<template>
  <div class="px-5" id="page-dashboard">

    <b-row>
      
    </b-row>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'dashboard',
  data() {
    return {
      applicationFields: [
        { key: 'company_id', label: '' },
        { key: 'name', label: 'Namn' },
        {
          key: 'org_number',
          label: 'Org. Nummer'
        },
        { key: 'created_at', label: 'Datum' },
        {
          key: 'actions',
          label: ''
        }
      ],
      applicationData: [],
    }
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
    statChartData: function() {
      return {
        is_loading: false,
        data_loaded: false,
        
      };
    },
    
  },
  mounted() {
    
  },
  watch: {
    currentCompanyId(newValue, oldValue) {
      if (newValue) {
    
      }
    },
    currentPeriodId(newValue, oldValue) {
      if (newValue) {
    
      }
    }
  },
  methods: {
    
  }
};
</script>

<style lang="scss" scoped>
  #page-dashboard {
    .custom-card-body-2-column {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      width: 100%;
      height: 100%;
      padding: 2rem 0rem;
      .title {
        flex: 1;
        position: relative;
        font-size: 2.2rem;
        font-weight: 600;
        color: #595d63;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          opacity: 0.08;
          position: absolute;
          z-index: 0;
          width: 120px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .data {
        flex: 1;
        display: flex;
        align-items: center;
        margin-left: 3rem;
        .member-icon-1 {
          display: inline-block;
          width: 0.45rem;
          height: 0.45rem;
          flex: 0 0 0.45rem;
          border-radius: 100%;
          margin-bottom: 0.12rem;
          margin-right: 0.8rem;
          background-color: #93a2dd !important;
        }
        .member-icon-2 {
          display: inline-block;
          width: 0.45rem;
          height: 0.45rem;
          flex: 0 0 0.45rem;
          border-radius: 100%;
          margin-bottom: 0.12rem;
          margin-right: 0.8rem;
          background-color: #5d78ff !important;
        }
        .member-icon-3 {
          display: inline-block;
          width: 0.45rem;
          height: 0.45rem;
          flex: 0 0 0.45rem;
          border-radius: 100%;
          margin-bottom: 0.12rem;
          margin-right: 0.8rem;
          background-color: #646c9a !important;
        }
      }
    }
    .membership-acquisition {
      .gender-girl-line {
        width: 15px;
        height: 5px;
        border-radius: 3px;
        background-color: #5d78ff !important;
      }
      .gender-boy-line {
        width: 15px;
        height: 5px;
        border-radius: 3px;
        background-color: #93a2dd !important;
      }
      .gender-other-line {
        width: 15px;
        height: 5px;
        border-radius: 3px;
        background-color: #646c9a !important;
      }
    }
  }
</style>
